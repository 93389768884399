<template>
  <div class="ClientDataDilution">
    <transition-group name="Transition__fade">
      <!-- Dilution Rate -->
      <div
        v-if="requestsProgress.dilution"
        class="column"
        key="dilution"
      >
        <label class="fs-18 mt-22 mb-20">
          Dilution:
          <span class="fc-light fs-16">{{ avgDilutionRate }}%</span>
        </label>
        <canvas ref="dilution-chart" />
      </div>
      <!-- Non-payments -->
      <div
        v-if="requestsProgress.nonpayments"
        class="column"
        key="nonpayments"
      />
    </transition-group>
  </div>
</template>

<script>
/* eslint-disable object-shorthand */
// Packages
import Chart from 'chart.js'
import moment from 'moment'
// Helpers
import { Invoice } from '../../../../utils/api'
import { transformRawInvoice } from '../../../../utils/helpers'


export default {
  name: 'ClientDataDilution',

  async created () {
    this.currentMonth = moment().month()
    this.progressStart()
    await this.constructCharts()
    this.progressFinish()
  },

  beforeRouteLeave (to, from, next) {
    if (this.charts.dilution) this.charts.dilution.destroy()
    next()
  },

  data () {
    return {
      avgDilutionRate: null,
      chartData: {
        dilution: null,
        nonpayments: null,
        shortpays: null,
      },
      chartDataRaw: {
        dilution: null,
      },
      charts: {
        dilution: null,
      },
      currentMonth: null,
      paidInvoices: [],
      requestsProgress: {
        dilution: false,
        nonpayments: false,
        shortpay: false,
      },
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    async constructCharts () {
      const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      const chartLabelByCurrentMonth = [
        ...monthAbbr.slice(this.currentMonth + 1),
        ...monthAbbr.slice(0, this.currentMonth + 1)
      ]

      try {
        await this.getChartData()
        this.constructDilutionChart(chartLabelByCurrentMonth)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data Dilution "Construct Charts"',
          {
            chartLabelByCurrentMonth,
            config: error.config,
            data: this.$data,
            monthAbbr,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue constructing the charts' })
      }
    },

    constructDilutionChart (labels) {
      // Y axis labels: $ amounts
      // X axis labels: Months (abbreviated), current month last
      if (!this.$refs['dilution-chart']) return // Avoids Toastr errors when switching data views
      const ctx = this.$refs['dilution-chart'].getContext('2d')
      const fillGradient = ctx.createLinearGradient(0, 0, 0, 500);
      // Add three color stops
      fillGradient.addColorStop(0, 'rgba(26, 144, 235, .1)');
      fillGradient.addColorStop(1, 'rgba(255, 255, 255, .1)');

      const thisComponent = this

      // Stupid fucking ESLINT
      // eslint-disable-next-line no-new
      this.$set(this.charts, 'dilution', new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            data: thisComponent.chartData.dilution,
            backgroundColor: fillGradient,
            borderColor: '#1991EB',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            pointBackgroundColor: '#2CA0F7',
            pointBorderColor: '#FFF',
            pointBorderWidth: 2,
            pointHitRadius: 15,
            pointRadius: 4,
          }],
        },
        options: {
          defaultFontColor: '#9EA0A5',
          defaultFontFamily: "'Roboto', 'sans-serif'",
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 21,
              },
            }],
            yAxes: [{
              gridLines: {
                color: '#EAEDF3',
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                callback: (value) => {
                  if (value >= 1000) {
                    return `$ ${Number(value / 1000).toFixed(2)}K`
                  }
                  return `$ ${Number(value).toFixed(2)}`
                },
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 27,
              },
            }],
          },
          title: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem) => {
                let label = tooltipItem.label || ''
                // Label should equal default data point value + (dilution rate for that month)
                const dilutionAmount = Number(tooltipItem.value)
                const monthlyTotal = Number(
                  thisComponent.chartDataRaw.dilution[tooltipItem.xLabel.toLowerCase()].total
                )

                if (label) {
                  label += ` (${(dilutionAmount / monthlyTotal * 100).toFixed(2)}%)`
                } else {
                  label = `${dilutionAmount} (${(dilutionAmount / monthlyTotal * 100).toFixed(2)}%)`
                }

                return label
              }
            },
          },
        }
      }))
    },

    async getChartData () {
      // dilution rate
      try {
        // Get client's paid invoices
        const invoices = (await Invoice.queryList({
          client_id: this.client.id,
          include: 'all',
          status: 'paid',
        }, 1000)).data.rows

        invoices.forEach(invoice => this.paidInvoices.push(transformRawInvoice(invoice)))

        // Calculate the average dilution rate
        // Dilution % = total unpaid / total amount
        const paidAmt = this.paidInvoices.reduce((acc, cur) => {
          const totalDebtorPayments = cur.debtor_payment.reduce((totalPayments, currentPayment) => {
            return totalPayments + currentPayment.amount
          }, 0)
          return acc + totalDebtorPayments
        }, 0)
        const totalAmt = this.paidInvoices.reduce((acc, cur) => acc + cur.amounts.total, 0)
        this.avgDilutionRate = ((totalAmt - paidAmt) / totalAmt).toFixed(2)

        // Divide invoices into months based on...
        const months = {}
        this.paidInvoices.forEach(invoice => {
          const whatMonth = this.numberToMonth(moment(invoice.paid_date).month())
          if (!months[whatMonth]) {
            months[whatMonth] = {
              dilution: null,
              invoices: [],
              total: null,
            }
          }
          months[whatMonth].invoices.push(invoice)
        })
        // Total value for each month in months
        for (let i = 0; i <= 11; i++) {
          if (months[this.numberToMonth(i)]) {
            // Month exists
            const monthAmt = months[this.numberToMonth(i)].invoices.reduce((acc, cur) => acc + cur.amounts.total, 0)
            const monthPaid = months[this.numberToMonth(i)].invoices.reduce((acc, cur) => {
              const totalDebtorPayments = cur.debtor_payment
                .reduce((totalPayments, currentPayment) => totalPayments + currentPayment.amount, 0)
              return acc + totalDebtorPayments
            }, 0)
            months[this.numberToMonth(i)].dilution = ((monthAmt - monthPaid) / monthAmt).toFixed(2)
            months[this.numberToMonth(i)].total = monthAmt.toFixed(2)
          } else {
            // Month does NOT exist
            months[this.numberToMonth(i)] = { dilution: 0, invoices: [], total: 0 }
          }
        }

        // Save the months information (invoices, dilution, and total per month)
        this.$set(this.chartDataRaw, 'dilution', months)

        // Order the months data into an array from january's to december's data
        const orderedMonths = []
        const calendarMonths = ['jan', 'feb', 'mar', 'apr', 'may', 'june', 'july', 'aug', 'sept', 'oct', 'nov', 'dec']
        calendarMonths.forEach(month => orderedMonths.push(months[month].dilution))

        // Reorder the data to match chart labels (our current month is last on the x-axis)
        const reorderedMonths = [
          ...orderedMonths.slice(this.currentMonth + 1),
          ...orderedMonths.slice(0, this.currentMonth + 1)
        ]

        await this.$set(this.chartData, 'dilution', reorderedMonths)
        await this.$set(this.requestsProgress, 'dilution', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data Dilution "Get Chart Data: Dilution Rate"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting data for dilution rate' })
      }

      // TODO: nonpayments
      try {
        const payments = []

        await this.$set(this.chartData, 'nonpayments', payments)
        await this.$set(this.requestsProgress, 'nonpayments', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data Dilution "Get Chart Data: Nonpayments"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting data for non-payments' })
      }

      // TODO: shortpays
      try {
        const payments = []

        await this.$set(this.chartData, 'shortpays', payments)
        await this.$set(this.requestsProgress, 'shortpays', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data Dilution "Get Chart Data: Shortpays"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting data for shortpays' })
      }
    },

    numberToMonth (number) {
      if (number === 0) return 'jan'
      if (number === 1) return 'feb'
      if (number === 2) return 'mar'
      if (number === 3) return 'apr'
      if (number === 4) return 'may'
      if (number === 5) return 'june'
      if (number === 6) return 'july'
      if (number === 7) return 'aug'
      if (number === 8) return 'sept'
      if (number === 9) return 'oct'
      if (number === 10) return 'nov'
      if (number === 11) return 'dec'
    },
  },
}
</script>

<style lang="sass">
.ClientDataDilution
</style>