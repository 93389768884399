import { render, staticRenderFns } from "./client-data-dilution.vue?vue&type=template&id=36b4f758&"
import script from "./client-data-dilution.vue?vue&type=script&lang=js&"
export * from "./client-data-dilution.vue?vue&type=script&lang=js&"
import style0 from "./client-data-dilution.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frank/Documents/RacknerProjects/Bobtail/bobtail_reset_6-3/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36b4f758')) {
      api.createRecord('36b4f758', component.options)
    } else {
      api.reload('36b4f758', component.options)
    }
    module.hot.accept("./client-data-dilution.vue?vue&type=template&id=36b4f758&", function () {
      api.rerender('36b4f758', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/client/profile/data/client-data-dilution.vue"
export default component.exports